import React from "react";
import "../App.css";
import tortoise from '../assets/tortoise.png'
import PCBs from '../assets/PCBs.png'
import img_host from '../assets/host.png'
import img_slowmotion from '../assets/slowmotion.png'
import img_servo from '../assets/servo.png'
import dccmm from '../assets/dccmm.png'
import locoxpress from '../assets/locoxpress.png'

function Hardware() {
  return (
  <div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>Electronics</h1>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>The world's most advanced point controllers</h2>
              <p>We are offering a series of world-class digital point controllers for slow-motion and servo drives.</p>
              <p>Equipped with advanced feedback functionality, the controllers can detect malfunction points and failures in wiring. A malfunctioning point will instantaneously be reported to the central unit and routes using the point will be prevented.</p>
              <p>The controllers are connected in a chain with one central unit, which efficiently and securely distributes the power for up to 60 units.</p>
            </div>
            <div className='item'>
              <center>
                <img src={PCBs} srcSet={PCBs} style={{width: '100%'}}/>
              </center>
            </div>
            <div className='item'>
              <h2>Tell us what you think about these products</h2>
              <p>All our electronic devices are being tested in an early prototype version. If you like what you see, please tell us. Any ideas for other products and features are received with pleasure.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>Host</h1>
      <h2>The central unit; external connections and power provider</h2>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>USB interface</h2>
              <p>The world's simplest electronics modules for model railways start with the Host device. This creates a link between your computer, command station and other attached Railmagic devices. It integrates power and a high-speed communication link through what is called the RM-chain. The RM-chain connects between devices with only one standard Ethernet cable.</p>
              <p>Your computer is often doing a lot of background processes which might introduce a lag in the communication with connected devices, e.g. command stations. But we have designed the interface as a highly critical device which your computer will therefore prioritize and guarantee reliable communication with. This is needed to collect track occupancy data because each millisecond counts.</p>
            </div>
            <div className='item first'>
              <center>
                <img src={img_host} srcSet={img_host} style={{width: '100%'}}/>
                <i>Prototype of Host</i>
              </center>
            </div>
            <div className='item'>
              <h2>48 W of power</h2>
              <p>The Host provides enough power for all devices you can ever think about connecting in the RM-chain. An intelligent power scheme is used to prioritise the resources among the connected devices. For instance will the switching of points be postponed a few seconds if more critical processes need the power.</p>
              <p>The total power available on the RM-chain is 48 W. And as the power comes from an electrical galvanic isolated power source, you will not have any problems where you need opto-couplers etc.</p>
            </div>
            <div className='item'>
              <h2>DCC and MM control</h2>
              <p>In case you want to use Railmagic devices without using our control panel or other computer control, the Host is listening to the track output signal for commands related to accessories. When you switch a point from your command station, the Host will duplicate this command to all Railmagic point motor controllers. You can therefore use and operate Railmagic point controllers from your command station.</p>
              <center>
                <img src={dccmm} srcSet={dccmm} style={{width: '100%'}}/>
              </center>
            </div>
            <div className='item'>
              <h2>Optional LocoNet or XpressNet</h2>
              <p>The optional LocoNet or XpressNet link allows you to use traditional accessories together with the Railmagic system. It uses the critical device scheme to guarantee perfect timing of critical data transfers with the computer.</p>
              <p>At this time, the interface only works for controlling points and other accessory addresses. But when self-driving behaviour is implemented in the Railmagic system, this interface will allow full control of attached command stations.</p>
              <p>However, note that advanced features like the failure reporting of points only work with Railmagic's point controllers, so if you are going to invest in new point controllers, we strongly advise you to consider our point controllers with a lot of new features.</p>
              <p>The interface is electrical galvanic isolated from the computer and the RM-chain.</p>
              <center>
                <img src={locoxpress} srcSet={locoxpress} style={{width: '100%'}}/>
              </center>
            </div>
            <div className='item'>
              <h2>Easy setup from a computer</h2>
              <p>You can easily connect a new device in the RM-chain and control it from our application. The RM-chain is automatically scanned before it powers up to detect changes in the order of attached devices.</p>
              <p>To easily access the configuration of a device, simply press the button on the device, and a configuration menu will pop up in the application. No need to remember device addresses and no conflicts occur when layouts are combined.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>Slowmotion</h1>
      <h2>The world's most advanced slow-motion point motor controller</h2>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>Digitalize point motors</h2>
              <p>If you think it is hard to digitalize your points - and don't see a reason to do so either, hopefully, the advanced features mentioned below will make you realize all the benefits. The main reason to digitalize is of course to be able to use our control panels and self-driving trains in the future.</p>
              <p>This device lets you digitalize slow-motion point motors like the Circuitron Tortoise and many other types including the ones that stall and cut connection at the final position.</p>
            </div>
            <div className='item first'>
              <center>
                <img src={img_slowmotion} srcSet={img_slowmotion} style={{width: '100%'}}/>
                <i>Prototype of Slowmotion</i>
              </center>
            </div>
            <div className='item'>
              <center>
                <img src={tortoise} />
              </center>
            </div>
            <div className='item'>
              <h2>Simpler wiring</h2>
              <p>When you want to ensure that the point motor has finished switching to the new position, it's common to use an electrical switch on the motor. This typically more than doubles the number of wires to the motor from 2 to 5. Instead of increasing the wiring complexity, the Slow-motion device carefully monitors the current to the motor. Two types of point motors exist; the ones that stall at the end and thereby increase the current consumption, and the ones that switch off at the end and thereby terminate the current consumption. We measure the duration and changes in current consumption to determine if the final position has been reached.</p>
              <p>By using our control panel, the point position feedback automatically lights up the right indicator without the need to solder extra wires.</p>
            </div>
            <div className='item'>
              <h2>Failure reporting</h2>
              <p>There will be circumstances where you accidentally break or get the wires to a point motor shorted together. The Slowmotion device handles both situations and reports the state of the point back to the system.</p>
              <p>With our control panel, both indicators flash red when a point cannot reach the wanted position.</p>
            </div>
            <div className='item'>
              <h2>Adjustable outputs</h2>
              <p>The speed of the slow-motion motors can be regulated by changing the supply voltage. We have integrated a voltage source that you can select between 5-12 V from within our application. This suits all slow-motion motors we know about.</p>
              <p>Other types of slow-motion motors use quite a bit more current than the Circuitron Tortoise. But don't worry, each output handles more than 100 mA and is protected against over-current conditions.</p>
            </div>
            <div className='item'>
              <h2>Cheaper than digital motors</h2>
              <p>Even though price shouldn't be the main reason for choosing a product, it is worth mentioning that the additional cost of digital slow-motion motors over analogue ones is around $100-$150 for 8 pieces. Therefore our Slowmotion device is a cheaper solution adding a lot of features as well.</p>
            </div>
            <div className='item'>
              <h2>Self-powered</h2>
              <p>Sometimes the tracks get shorted, and ordinary decoders powered by the same track power will stop working. All Railmagic devices run from a separate power source delivered together with a high-speed communication bus through a single cable.</p>
              <p>You are therefore ensured by our products that points are switched even when trains have derailed and the tracks are shorted. Further, the bi-directional communication bus ensures that the state of the point can be read back.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>Servo</h1>
      <h2>The world's most advanced servo motor controller tailored for model railways</h2>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>Many use-cases</h2>
              <p>Servo motors can be used in many interesting areas for railway modellers; switching points, semaphores, railway barriers, opening doors etc.</p>
              <p>However, servo motors need to be controlled by a dedicated device designed for the special need. For railway modellers, not many solutions exist. Railmagic has created the most feature-rich product on the market.</p>
            </div>
            <div className='item first'>
              <center>
                <img src={img_servo} srcSet={img_servo} style={{width: '100%'}}/>
                <i>Prototype of Servo</i>
              </center>
            </div>
            <div className='item'>
              <h2>Intelligent power</h2>
              <p>Because servo motors are extremely power-hungry, you cannot afford to waste more power than necessary. We have reduced the power waste to a fifth of other solutions by using active voltage conversion.</p>
              <p>When the system is powered up, the servo motors are enabled sequentially to reduce the inrush current. Therefore hundreds of servos can be powered from a single Host device.</p>
            </div>
            <div className='item'>
              <h2>Cheap but difficult</h2>
              <p>Servo motors cost only a few dollars on Ebay and Amazon. But they are hard to operate because they are designed for a different purpose.</p>
              <p>For instance, they need an extremely precise voltage and high current to remain regulated and stable. The power-up and power-down cycles should be well determined etc. Our controller ensures that all conditions are met and that the servo remains in a regulated and stable state.</p>
              <p>In case the servo is forced into an unstable state by an external force, the increase in current consumption is measured and the power to the servo is removed. The failure reporting will be activated.</p>
            </div>
            <div className='item'>
              <h2>Failure reporting</h2>
              <p>There will be circumstances where you accidentally break or get the wires to a point motor shorted together. The Servo device handles both situations and reports the state of the point back to the system.</p>
              <p>With our control panel, both indicators flash red when a point cannot reach the wanted position.</p>
            </div>
            <div className='item'>
            <h2>Self-powered</h2>
              <p>Sometimes the tracks get shorted, and ordinary decoders powered by the same track power will stop working. All Railmagic devices run from a separate power source delivered together with a high-speed communication bus through a single cable.</p>
              <p>You are therefore ensured by our products that points are switched even when trains have derailed and the tracks are shorted. Further, the bi-directional communication bus ensures that the state of the point can be read back.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>Solenoid</h1>
      <h2>Point controller for solenoids</h2>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>For classical point-switching</h2>
              <p>The solenoids are electrical galvanic isolated from the RM-chain and are powered usually by the digital track power (alternating DC). The outputs are designed as solid-state switches with no mechanical moving parts for reliability.</p>
              <p>Can also be powered by AC or DC. For DC, the outputs will pull down to ground and the solenoids must have a common positive supply.</p>
            </div>
            <div className='item'>
              <h2>Failure reporting</h2>
              <p>In case the external power is lost, the system will report the points in failure mode until the power is re-established.</p>
              <p>All outputs are current-protected and the current is monitored to detect if the point actually switches (wire broken).</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
);
}
export default Hardware;