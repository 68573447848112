import React from "react";
import "../App.css";
import install_chrome from '../assets/install_chrome.png'
import install_edge from '../assets/install_edge.png'
import overview_m from '../assets/overview_m.png'
import overview_s from '../assets/overview_s.png'
import ReactPlayer from "react-player";

function Software() {
  return (
    <div>
    <div className='content'>
      <div className='imgcontainer'>
        <div className="player-wrapper">
          <div className="player-wrapper-int">
            <ReactPlayer url='https://www.railmagic.com/web2.mp4' width={'1440px'} height={'700px'} className='react-player' loop playing muted playsinline />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>The world's simplest interlocking</h2>
              <p>Everything works straight away. When you have drawn your track layout, the system can automatically determine all possible routes. Interlocking is instantly working!</p>
            </div>
            <div className='item'>
              <h2>Occupancy</h2>
              <p>Track occupancy normally demands a lot of hardware and wiring, but we are going to make a virtual indication of occupancy. We simply assume a train is moving from one block to the next when the route is terminated. Therefore, when a route is terminated, the leaving block is assumed empty while the receiving block is assumed occupied and the red indicators are turned on. We will show a demonstration when implemented.</p>
            </div>
            <div className='item'>
              <h2>Absolutely free</h2>
              <p>Our software panel is absolutely free to use. Get used to the awesome features while we are waiting for the real thing!</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='imgcontainer'>
        <div className="imgimage">
          <picture>
            <source srcSet={overview_s} media={'(max-width: 749px)'} />
            <img src={overview_m} className='overview' />
          </picture>
        </div>
      </div>
      <div className='section'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>Railmagic Realm</h2>
              <p>Our software panel and application used to configure the system is called the Railmagic Realm. This is the program that generates the graphics you see and interact with.</p>
              <p>Technically speaking, the Railmagic Realm is the front-end running in your web-browser.</p>
            </div>
            <div className='item'>
              <h2>Railmagic Engine</h2>
              <p>Behind the Railmagic Realm is running the Railmagic Engine. This is the program that does all the complex calculations.</p>
              <p>Technically speaking, the Railmagic Engine is the back-end running an executable program. This is installed as a typical Windows program.</p>
            </div>
            <div className='item'>
              <h2>Command station</h2>
              <p>Your command station communicates with the Railmagic Engine either directly by Ethernet or USB, or by the optional LocoNet or XpressNet link on the Host device.</p>
            </div>
            <div className='item'>
              <h2>Host device</h2>
              <p>The Host device is the central unit that delivers power and instructions to the Railmagic chain of devices. If your command station is supported by Ethernet or USB, the Host device is not needed to try the software panel.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item' style={{margin: '40px'}}>
              <center>
                <p>Contains installation guidelines!</p>
                <a href="https://railmagic.com/manual.pdf" target="_blank">
                <div className='button'>
                  <div style={{ margin: "4px" }}>Open the User Manual</div>
                </div>
                </a>
              </center>
            </div>
            <div className="nomobile">
              <div className='item' style={{margin: '40px'}}>
                <center>
                  <p>You are one click away...</p>
                  <a href="https://railmagic.com/app" target="_blank">
                  <div className='button'>
                    <div style={{ margin: "4px" }}>Launch Railmagic Realm</div>
                  </div>
                  </a>
                </center>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className="spacer"></div>
    <div className='content'>
      <div className='section_noimg'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>Supported command stations</h2>
              <p>The following command stations are supported by Ethernet:<br />Roco z21 white (unlocked)<br />Roco Z21 black<br />Märklin CS2<br />Märklin CS3<br />ESU Ecos<br />Digikeijs DR5000 (using z21 protocol)</p>
              <p>The following command stations are supported by USB:<br />None</p>
            </div>
            <div className='item'>
              <h2>Early software limitations</h2>
              <p>Points must be of the types normal left/right, cross, or double slip. Currently no support for single slip, three-way, and Y-way.</p>
              <p>Signals can only have two aspects: red and green. Later we will add a huge range of signals designed to resemble prototypical signals from individual countries.</p>
              <p>Track indicators cannot show occupied tracks in red.</p>
              <p>We are still working on how to do entry-exit routing only with mouse clicks.</p>
              <p>Routes are found automatically and cannot be changed.</p>
              <p>Railmagic Engine only for Windows, but Linux version exists for expert users on request.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
);
}
export default Software;