import React from "react";
import "../App.css";

function Error() {
  return (
  <div>
    <div className='content'>
      <div className='section_noimg'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>Page not found - 404</h2>
              <p>The address is invalid and no page could be found.</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
);
}
export default Error;