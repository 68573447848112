import React from "react";
import "../App.css";
import ReactPlayer from "react-player";

function Playground() {

  return (
  <div>
    <div className='content'>
      <div className='section_noimg'>
      <h1>You found the secret playground for testing</h1>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div className='content'>
      <div className='imgcontainer'>
        <div className="player-wrapper">
          <div className="player-wrapper-int">
            <ReactPlayer url='https://www.railmagic.com/web1.mp4' width={'1440px'} height={'700px'} className='react-player' loop playing muted playsinline />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='itemextcontainer'>
          <div></div>
          <div className='itemcontainer'>
            <div className='item'>
              <h2>The world's simplest interlocking</h2>
              <p>Everything works straight away. When you have drawn your track layout, the system can automatically determine all possible routes. Interlocking is instantly working!</p>
            </div>
            <div className='item'>
              <h2>Occupancy</h2>
              <p>Track occupancy normally demands a lot of hardware and wiring, but we are going to make a virtual indication of occupancy. We simply assume a train is moving from one block to the next when the route is terminated. Therefore, when a route is terminated, the leaving block is assumed empty while the receiving block is assumed occupied and the red indicators are turned on. We will show a demonstration when implemented.</p>
            </div>
            <div className='item'>
              <h2>Absolutely free</h2>
              <p>Our software panel is absolutely free to use. Get used to the awesome features while we are waiting for the real thing!</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
);
}
export default Playground;