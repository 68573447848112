import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import light_l from '../assets/light_l.png'
import light_m from '../assets/light_m.png'
import track_l from '../assets/track_l.png'
import track_m from '../assets/track_m.png'
import ReactPlayer from "react-player";

function Home() {
  return (
    <div>
      <div className='content'>
        <div className='imgcontainer'>
          <div className="player-wrapper">
            <div className="player-wrapper-int">
              <ReactPlayer url='https://www.railmagic.com/web1.mp4' width={'1440px'} height={'700px'} className='react-player' loop playing muted playsinline />
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='itemextcontainer'>
            <div></div>
            <div className='itemcontainer'>
              <div className='item'>
                <h2>The world's simplest interlocking</h2>
                <p>Imagine controlling your model railway layout from an authentic control panel. We have designed a control panel where no wiring is needed. You can simply attach individual tiles together to create a large mimic panel. The panel automatically calculates possible routes and you can use entry-exit routing or old-fashioned switching of individual points. When a route is activated, all relevant points and signals are securely locked to prevent derailments and crashes.</p>
              </div>
              <div className='item'>
                <h2>... also for old eras</h2>
                <p>If you like the older eras, you can also choose to use switching of individual points instead of entry-exit routing. When you have created a valid route by switching all necessary points, the protecting signal can be set and all points in the route are locked in their current state.</p>
                <p>Entry-exit routing is not implemented yet as it is difficult to operate using a mouse. So give the old-fashioned routing a try - as shown in the video above.</p>
              </div>
              <div className='item'>
                <h2>Security at point level</h2>
                <p>If your layout is not yet prepared for digital control we are going to offer a series of world-class point controllers. Our controllers will be power efficient and controlled from a central unit using a single cable. The controllers have advanced feedback functionality and can therefore detect malfunction points. A malfunction point will instantaneously be reported and routes using the point will be prevented. In other words, a train will never be allowed into a point of unknown state.</p>
              </div>
              <div className='item'>
                <h2>Absolutely free</h2>
                <p>Our software panel is absolutely free to use. Get used to the awesome features while we are waiting for the real thing!</p>
                <Link to='/software'>
                  <center><div className='button'>
                    <div style={{ margin: "4px" }}>Discover the software panel</div>
                  </div></center>
                </Link>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <div className='content'>
        <div className='imgcontainer'>
          <div className="imgimage">
            <picture>
              <source srcSet={light_m} media={'(max-width: 1174px)'} />
              <img src={light_l} className='cropped' />
            </picture>
          </div>
          <div className='imgheader'>Different approach</div>
          <div className='imgtext'>Finally a simple train automation system,<br />fundamentally changed for the better</div>
          <div className='imgtextcenter'><i>VISION</i></div>
        </div>
        <div className='section'>
          <div className='itemextcontainer'>
            <div></div>
            <div className='itemcontainer'>
              <div className='item'>
                <h2>The role of the operator</h2>
                <p>Hardly are you able to control more than a few trains at once without the help of some train automation. Where computer-controlled systems often automate everything, we separate the role of the dispatcher from the engine driver. By providing prototypical control panels, we let you become the dispatcher, while our system uses artificial intelligence to act as all the engine drivers.</p>
                <p>Only when you feel for it and activate the exhibition mode, will our system act as the dispatcher and let the layout be fully automated without user interaction.</p>
              </div>
              <div className='item'>
                <h2>Solution-orientated</h2>
                <p>As a modeller, it can be difficult to navigate through the jungle of products needed for computer control of model railways. No other company takes responsibility for the entire solution, instead, they manufacture only hardware or sell software.</p>
                <p>Railmagic is the only company that aims at delivering the entire solution. This ensures an integrated solution where we are the only ones to blame when things don't work as expected.</p>
              </div>
              <div className='item'>
                <h2>Pioneers</h2>
                <p>It's no secret, that Railmagic is a new company. We need pioneers, who want the electronics for model railways to be simpler and better.</p>
                <p>The benefit you receive by joining us early is that you can affect how our solution becomes. Customer feedback is going to be key for feature development. Our future solution for fully automatic operation will work without electrically isolated track sections. No more wire spaghetti and electrical difficulties. We have reinvented the method for detecting the movement of trains on a layout. Are you coming with us?</p>
                {(false) && <center><div className='button'>
                  <div style={{ margin: "4px" }}>Sign-up for our newsletter</div>
                </div></center>}
              </div>
              <div className='item'>
                <h2>Best in class</h2>
                <p>We are going to design electronic products, which are the best in class. With our high level of skills and many years of product development, we are going to serve the most technologically advanced model railway products ever, but with user-friendliness for non-tech people.</p>
                <Link to='/hardware'>
                  <center><div className='button'>
                    <div style={{ margin: "4px" }}>Discover our hardware products</div>
                  </div></center>
                </Link>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <div className='content'>
        <div className='imgcontainer'>
          <div className="imgimage">
            <picture>
              <source srcSet={track_m} media={'(max-width: 1174px)'} />
              <img src={track_l} className='cropped' />
            </picture>
          </div>
          <div className='imgheader'>Everything is on track</div>
          <div className='imgtext'>Amazing self-driven model railway system<br />developed through four integration steps</div>
          <div className='imgtextcenter'><i>PROGRESS</i></div>
        </div>
        <div className='section'>
          <div className='itemextcontainer'>
            <div></div>
            <div className='itemcontainer'>
              <div className='item'>
                <h2>The world's simplest interlocking</h2>
                <p>First step; a completely free software version of our control panel. If your points are digitally controlled, you are ready to give it a try. However, you need a <b>supported</b> command station with an Ethernet interface - alternatively, we might later support USB interfaces as well. Sign up for our newsletter to get informed about this.</p>
                <p>The list of supported command stations is shown in the software menu. Send us an email to request support for your command station!</p>
                <Link to='/software'>
                  <center><div className='button'>
                    <div style={{ margin: "4px" }}>Discover the software panel</div>
                  </div></center>
                </Link>
              </div>
              <div className='item'>
                <h2>The world's best point controllers</h2>
                <p>Second step; In our laboratory is a series of hardware products taking form. First out is point motor controllers with features never seen before. Even though the products are not ready for sale yet, you will find detailed descriptions of features under the hardware menu.</p>
                <Link to='/hardware'>
                  <center><div className='button'>
                    <div style={{ margin: "4px" }}>Discover our hardware products</div>
                  </div></center>
                </Link>
              </div>
              <div className='item'>
                <h2>The world's finest control panel</h2>
                <p>Third step; The software version of our control panel has some nice features, but might not look like a typical computer control program. The reason for this is, that the software is an emulator of a real hardware control panel. We are working on making an awesome tile-based panel where each tile is fastened by one screw only and no electrical wires. Absolutely no programming is needed to transfer the design from the software panel to the real hardware panel.</p>
              </div>
              <div className='item'>
                <h2>The world's easiest computer-control</h2>
                <p>Fourth step; computer control of any layout without electrical isolation of track sections. We are investigating a disruptive technology to determine the position of model railway locomotives without the need for electrically isolated occupancy detectors. Neither will modifications be necessary inside the locomotives themselves. Our technology is top secret until further testing has been conducted.</p>
                <p>This is the solution we call "instant train automation".</p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;




/*
        <picture>
          <source media='(min-width: 1175px)' srcSet={light_l} />
          <img src={light_m} className='cropped' />
        </picture>
*/