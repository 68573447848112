import React from 'react';
import { createBrowserRouter, RouterProvider, Route, Link, NavLink, Outlet, ScrollRestoration } from "react-router-dom";
import './App.css';
import './Nav.css';
import { useMediaQuery } from 'react-responsive'
import { IconContext } from "react-icons";
import { MdOutlineArrowForwardIos, MdOutlineMenu, MdOutlineHome, MdOutlineShoppingBasket, MdOutlineDownload, MdMenu, MdClose } from "react-icons/md";
import { CiCircleChevRight } from "react-icons/ci";
import { MdOutlineEmail, MdOutlinePostAdd } from "react-icons/md";
import { useState, useEffect } from "react";
import Home from './pages/Home';
import Hardware from './pages/Hardware';
import Software from './pages/Software';
import Error from './pages/Error';
import logo from './assets/logo_inverted.png';
import Playground from './pages/Playground';

// NavBar guide: https://www.youtube.com/watch?v=q4mr71mFBr4


function App() {
  const [viewMenu, viewMenuVal] = useState(0);
  const display1 = useMediaQuery({ query: '(max-width: 749px)' })
  const display2 = useMediaQuery({ query: '(max-width: 1124px)' })
  const display3 = useMediaQuery({ query: '(min-width: 1125px)' })
  const mail = "uk@";
  //    const display3 = useMediaQuery({ query: '(orientation: portrait)' })
  function toggleMenu() {
    if (viewMenu === 0) {
      viewMenuVal(1);
    } else {
      viewMenuVal(0);
    }
  }
  function closeMenu() {
    viewMenuVal(0);
  }
  useEffect(() => {
    function handleResize() {
      /*
      if (window.innerWidth < 750) {
        viewMenuVal(0);
      } else {
        viewMenuVal(1);
      }
      */
      const width = 1440;
      const widthmin = 980;
      const height = 700;
      let widthcol = 0;
      if (window.innerWidth < 600) {
        widthcol = window.innerWidth;
      }else if (window.innerWidth < 750) {
        widthcol = 600;
      }else if (window.innerWidth < 960){
        widthcol = window.innerWidth;
      }else if (window.innerWidth < 1175){
        widthcol = 960;
      }else if (window.innerWidth < 1440){
        widthcol = window.innerWidth;
      }else{
        widthcol = 1440;
      }
      let scale = 1.00;
      if (widthcol < widthmin) {
        scale = widthcol/widthmin;
      }
      let top = 0;
      if (scale < 1) {
        top = -height*(1-scale)/2;
      }
      let left = 0;
      if (window.innerWidth < 1440) {
        left = (widthcol-1440)/2;
      }else{
        left = 0;
      }
      //console.log("Resize: "+window.innerWidth+" "+left+" "+top+" "+scale);
      document.documentElement.style.setProperty("--VideoHeight", (height*scale).toString()+'px');
      document.documentElement.style.setProperty("--VideoLeft", (left).toString()+'px');
      document.documentElement.style.setProperty("--VideoTop", (top).toString()+'px');
      document.documentElement.style.setProperty("--VideoScale", (scale).toString());
    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  })
  
  function Layout() {
    return(
    <div className='extcontainer'>
      <ScrollRestoration />
      <div className='containerheader'>
        {/* HEADER */}
        <div></div>
          <div className='sticky'>
            <nav className='nav'>
              <div className='nav1'>
                <img src={logo} className='logo'/>
              </div>
              <div className='nav2'>
                <div className='navsmall' onClick={toggleMenu}>
                    {viewMenu === 1 &&
                      <div>
                        <IconContext.Provider value={{ size: '26px' }} >
                          <MdClose />
                        </IconContext.Provider>
                      </div>
                    }
                    {viewMenu !== 1 &&
                      <div>
                        <IconContext.Provider value={{ size: '26px' }} >
                          <MdMenu />
                        </IconContext.Provider>
                      </div>
                    }
                </div>
              </div>
              <div className='nav3'>
                {(viewMenu === 1 || !display1) &&
              <ul className='navmenu' onClick={closeMenu}>
                <li>
                  <Link to='/' className='navmenu'>
                    <div className='menu-item menu-point'>
                      <div>
                        <IconContext.Provider value={{ size: '26px' }} >
                          <MdOutlineHome />
                        </IconContext.Provider>
                      </div>
                      <div style={{ margin: "4px" }}>Overview</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to='/software' className='navmenu'>
                    <div className='menu-item menu-point'>
                      <div>
                        <IconContext.Provider value={{ size: '26px' }} >
                          <MdOutlineDownload />
                        </IconContext.Provider>
                      </div>
                      <div style={{ margin: "4px" }}>Software</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to='/hardware' className='navmenu'>
                    <div className='menu-item menu-point'>
                      <div>
                        <IconContext.Provider value={{ size: '26px' }} >
                          <MdOutlineShoppingBasket />
                        </IconContext.Provider>
                      </div>
                      <div style={{ margin: "4px" }}>Hardware</div>
                    </div>
                  </Link>
                </li>
              </ul>
              }
              </div>
            </nav>
          </div>
        <div></div>
      </div>
      <div className='container'>
        {/* CONTENT */}
        <div></div>
        <div>
          <Outlet />
        </div>
        <div></div>
      </div>
      <div className='container'>
    
        {/* FILLER */}
        <div></div>
        <div></div>
        <div></div>
    
      </div>
      <div className='containerfooter'>
        {/* FOOTER */}
        <div></div>
        <center>
          <div className='footer center'>
            <p><i>Railmagic ApS &copy; 2023 Denmark, {mail}railmagic.com</i></p>
          </div>
        </center>
        <div></div>
    
      </div>
    </div>
    )
  }
  
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          path: '/',
          element: <Home />
        },{
          path: 'software',
          element: <Software />
        },{
          path: 'hardware',
          element: <Hardware />
        },{
          path: 'playground',
          element: <Playground />
        }
      ]
    }
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

